import React, { useContext, useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDeepCompareEffect } from 'react-use';
import isPast from 'date-fns/is_past';
import isFuture from 'date-fns/is_future';
import { HotelBookingCard} from 'ui/cards';
import { SpinnerContainer, media } from 'ui';
import PageContext from 'contexts/page';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { getBookings } from 'api/profiles';
import { get } from 'lodash';
import { parseBookingDate } from 'utils/date';
import { getHotels } from '../api/hotels';
import { useCallback } from 'react';
import Spinner from 'components/Spinner';
import { convertToSlug } from './ConvertToSlug';

const NoReservation = styled.p`
  ${media.mobile`
    width: 90%;
    margin-left: 6%;
  `}
`
const BookingContainer = styled.div`
  ${media.tablet`
    margin-left: 9%;
  `}
`
const Title = styled.h2`
  color: #1A1A1A;
  font-size: 2rem;
  margin-bottom: 30px;
  margin-top: 100px;
  font-weight: 700;
  ${media.mobile`
    margin-left: 20px;
    font-size: 1.85rem;
  `}
`
const ProfilBooking = () => {
  const { t } = useTranslation();
  const { language } = useContext(PageContext);
  const [isLoading, setIsLoading] = useState(true);

  const { data: bookingsData } = useAsync({
    promiseFn: getBookings,
    includes: ['hotel', 'hotel.city'],
    language,
  });

  useEffect(() => {
    if(bookingsData){
      setIsLoading(false);
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingsData]);

  const bookings = useMemo(
      () =>
          (get(bookingsData, 'bookings') || []).filter(
              (booking) => booking.state === 'paid' || booking.state === 'registered_booking' || booking.state === 'cancelled' || booking.state === 'refunded',
          ),
      [bookingsData],
  );

  const hotelIds = useMemo(
      () => (bookings || []).map(({ hotel }) => hotel.id),
      [bookings],
  );

  const { data, run: fetchHotels } = useAsync({
    deferFn: useCallback(([params]) => getHotels(params), []),
  });

  useDeepCompareEffect(() => {
    if (!hotelIds || hotelIds.length === 0) {
      return;
    }
    fetchHotels({ language, options: { hotelIds } });
  }, [hotelIds]);

  const hotelsInfo = data?.body;

  const populateBookingsHotel = bookingArray => {
    const parsedArray = [];
    bookingArray.forEach(({ hotel, ...booking }) => {
      hotelsInfo &&
      hotelsInfo.forEach(
          hotelInfo =>
              hotelInfo.id === hotel.id &&
              parsedArray.push({ ...booking, hotel: { ...hotelInfo } }),
      );
    });
    return parsedArray;
  };

  const pastBookings = (
      populateBookingsHotel(bookings) || []
  ).filter(({ endAt, state, koobData }) => isPast(parseBookingDate(endAt)) && state !== 'refunded' && koobData.response.data.bookItem);

  const nextBookings = (
      populateBookingsHotel(bookings) || []
  ).filter(({ endAt, state, koobData }) => isFuture(parseBookingDate(endAt)) && state !== 'refunded' && koobData.response.data.bookItem);

  const cancelledBookings = (
    populateBookingsHotel(bookings) || []
  ).filter((booking) => booking.state === 'cancelled' || booking.state === 'refunded' && booking.koobData.response.data.bookItem);
  
  return (
    <BookingContainer>
        {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
        <>
          <div id={convertToSlug(t('profiles.header.bookingToCome'))}>
            <Title>{t('profiles.booking.newtitle1')}</Title>
            {nextBookings.length > 0 ?
              nextBookings.map(({ koobBookingId, hotel, startAt, endAt, koobData }) => (
                <HotelBookingCard
                  hotel= {hotel}
                  startAt ={startAt}
                  endAt = {endAt}
                  koobData = {koobData}
                  to={`/booking-details/${koobBookingId}`}
                />
            )) : <NoReservation>{t('profiles.booking.Noupcomings')}</NoReservation>
            }
          </div>
          <div id={convertToSlug(t('profiles.header.bookingPrevious'))}>
            <Title>{t('profiles.booking.newtitle2')}</Title>
            {pastBookings.length > 0 ?
              pastBookings.map(({ koobBookingId, hotel, startAt, endAt, koobData }) => (
                <HotelBookingCard
                  hotel= {hotel}
                  startAt ={startAt}
                  endAt = {endAt}
                  koobData = {koobData}
                  to={`/booking-details/${koobBookingId}`}
                />
            )) : <NoReservation>{t('profiles.booking.NoPrevious')}</NoReservation> } 
          </div>
          <div id={convertToSlug(t('profiles.header.bookingCancelled'))}>
            <Title>{t('profiles.booking.newtitle3')}</Title>
            {cancelledBookings.length > 0 ?
              cancelledBookings.map(({ koobBookingId, hotel, startAt, endAt, koobData }) => (
                <HotelBookingCard
                  hotel= {hotel}
                  startAt ={startAt}
                  endAt = {endAt}
                  koobData = {koobData}
                  to={`/booking-details/${koobBookingId}`}
                />
            )) : <NoReservation>{t('profiles.booking.NoCancelled')}</NoReservation> } 
          </div>
        </> 
       )
      }
  </BookingContainer>
  )
};

export default ProfilBooking;
